<template>
  <v-row class="mt-0 pt-0 px-5" justify="center">
    <v-col cols="12" class="py-0">
      <v-layout justify-center>
        <h2 class="color-text-main pt-4">
          CREAR COMISIÓN / COMPENSACIÓN VARIABLE
        </h2>
      </v-layout>
      <v-layout justify-center>
        <p class="color-text-third">
          Mes actual <strong> {{ listMonth[new Date().getMonth()] }} </strong>
        </p>
      </v-layout>
      <v-stepper alt-labels v-model="step">
        <v-stepper-header>
          <v-stepper-step color="#68cd86" :complete="step > 1" step="1">
            Crear
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step color="#68cd86" :complete="step > 2" step="2">
            Asignar usuarios
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step color="#68cd86" step="3">
            Adjuntar archivo
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-content step="1">
          <v-form ref="form">
            <v-row class="mt-4" justify="center">
              <v-col class="py-0" cols="7">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :rules="[(v) => !!v || 'Este campo es requerido']"
                      v-model="date"
                      label="Fecha mes de pago"
                      prepend-icon="mdi-calendar"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      color="#034f79"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    type="month"
                    :min="min"
                    v-model="date"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="py-0" cols="7">
                <v-autocomplete
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  v-model="form.cost_center_id"
                  item-value="id"
                  item-text="name"
                  :items="listCostCenter"
                  outlined
                  label="Centro de costos *"
                  color="#034f79"
                ></v-autocomplete>
              </v-col>
              <v-col class="py-0" cols="7">
                <v-autocomplete
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  v-model="form.client_id"
                  item-value="id"
                  item-text="name"
                  :items="listClients"
                  outlined
                  label="Cliente *"
                  color="#034f79"
                ></v-autocomplete>
              </v-col>
              <v-col class="py-0" cols="7">
                <v-autocomplete
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  v-model="form.country_c"
                  return-object
                  item-text="name"
                  :items="listCountry"
                  outlined
                  label="País *"
                  color="#034f79"
                ></v-autocomplete>
              </v-col>
              <v-col class="py-0" cols="12">
                <v-layout justify-center>
                  <v-btn
                    :loading="loading.btn"
                    class="mb-5"
                    @click="addCommission()"
                    color="#034f79"
                    dark
                    rounded
                  >
                    guardar comisión
                  </v-btn>
                </v-layout>
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-form ref="form1">
            <v-row align="center" class="mt-4">
              <v-col class="py-0" cols="6">
                <v-autocomplete
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  v-model="form2.action_id"
                  item-value="id"
                  item-text="name"
                  :items="listActions"
                  outlined
                  label="Rol en la comisión *"
                  color="#034f79"
                ></v-autocomplete>
              </v-col>
              <v-col class="py-0" cols="6">
                <v-autocomplete
                  color="#034f79"
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  v-model="document_number"
                  return-object
                  item-text="document_number"
                  :items="listUsers"
                  outlined
                  label="Lista de usuarios *"
                  placeholder="Seleccione el usuario"
                ></v-autocomplete>
              </v-col>
              <v-col cols="7">
                <template v-if="show.manager">
                  <p class="info-text-1 text-left">
                    <strong> Nombre completo: </strong> {{ infoUser.name }}
                  </p>
                  <p class="info-text-1 text-left">
                    <strong> Cédula: </strong> {{ infoUser.cc }}
                  </p>
                  <p class="info-text-1 text-left">
                    <strong> Campaña: </strong> {{ infoUser.campaignAsign }}
                  </p>
                  <p class="info-text-1 text-left">
                    <strong> Centro de costos: </strong>
                    {{ infoUser.constCenter }}
                  </p>
                  <p class="info-text-1 text-left">
                    <strong> Correo electrónico: </strong>
                    {{ infoUser.email }}
                  </p>
                  <p class="info-text-1 text-left">
                    <strong> Rol en el sistema: </strong>
                    {{ infoUser.role}}
                  </p>
                </template>
              </v-col>
              <v-col v-if="show.manager" class="py-0" cols="5">
                <v-layout justify-center>
                  <v-btn
                    :loading="loading.btn"
                    class="mb-5"
                    @click="addUserCommission()"
                    color="#034f79"
                    dark
                    rounded
                  >
                    Asignar usuario
                  </v-btn>
                </v-layout>
              </v-col>
            </v-row>
          </v-form>
          <v-divider></v-divider>
          <p class="color-text-third text-left mt-4">
            <strong> USUARIOS ASIGNADOS </strong>
          </p>
          <template>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Nombre</th>
                    <th class="text-left">Cédula</th>
                    <th class="text-left">email</th>
                    <th class="text-left">Campaña</th>
                    <th class="text-left">Centro de costos</th>
                    <th class="text-left">Estado</th>
                    <th class="text-left">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in listUserAssign" :key="item.id">
                    <td>{{ item.user.name }}</td>
                    <td>{{ item.user.document_number }}</td>
                    <td>{{ item.user.email }}</td>
                    <td>
                      <template v-if="item.user.campaign == null"> </template>
                      <template v-else>
                        {{ item.user.campaign.name }}
                      </template>
                    </td>
                    <td>
                      <template v-if="item.user.cost_center == null">
                      </template>
                      <template v-else>
                        {{ item.user.cost_center.name }}</template
                      >
                    </td>
                    <td>
                      <v-chip
                        label
                        dark
                        small
                        :color="getColorStatus(item.status_id)"
                      >
                        {{ item.status.name }}
                      </v-chip>
                    </td>
                    <td>
                      <v-btn
                        @click="openModalInactive(item)"
                        dark
                        color="red"
                        fab
                        icon
                      >
                        <v-icon> mdi-close </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-alert
              v-if="listUserAssign.length == 0"
              icon="mdi-information-outline"
              prominent
              text
              type="info"
            >
              <b>No se encontró información.</b>
            </v-alert>
          </template>

          <v-layout justify-center>
            <v-btn
              :loading="loading.btn"
              class="mb-5"
              @click="next()"
              color="#034f79"
              dark
              rounded
            >
              siguiente
            </v-btn>
          </v-layout>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-form ref="form3">
            <v-row justify="center" class="mt-4">
              <v-col style="color: #6f6f6f" class="pt-7" cols="12">
                <img
                  width="400"
                  class="mb-2"
                  src="../../../assets/img/commisions_example_csv.png"
                />
                <p class="mb-0">
                  * Opciones para la columna cédula:
                  <strong> documento, cc, cedula </strong>
                </p>
                <p class="mb-0">
                  * Opciones para la columna monto:
                  <strong> monto, valor, valores, montos </strong>
                </p>
                <small>
                  Para una lectura correcta del archivo es recomendable
                  <strong> NO USAR </strong> caracteres especiales.
                </small>
                <small> Se debe adjuntar por lo menos un archivo. </small>
                <hr style="border: none" class="mt-2 pb-2" />
                <v-btn color="red" class="mb-2" dark @click="download()">
                  Descargar ejemplo
                </v-btn>
                <hr style="border: none" />
                <small>
                  Este ejemplo aplica para el archivo de comisiones y de
                  compensación variables.
                </small>
                <v-file-input
                  class="mt-3"
                  v-model="form3.file"
                  color="#034f79"
                  outlined
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  label="Archivo de comisones"
                ></v-file-input>

                <v-file-input
                  class="mt-3"
                  v-model="form3.file2"
                  color="#034f79"
                  outlined
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  label="Archivo de compensación variable"
                ></v-file-input>
              </v-col>
              <v-col class="py-0" cols="6">
                <v-layout justify-end>
                  <v-btn
                    :loading="loading.btn"
                    class="mb-5"
                    @click="preview()"
                    color="#c1c1c1"
                    dark
                    rounded
                  >
                    Volver
                  </v-btn>
                </v-layout>
              </v-col>
              <v-col class="py-0" cols="6">
                <v-layout justify-initial>
                  <v-btn
                    :loading="loading.btn"
                    class="mb-5"
                    @click="addFileCommission()"
                    color="#034f79"
                    dark
                    rounded
                  >
                    Asignar archivo
                  </v-btn>
                </v-layout>
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>
      </v-stepper>
    </v-col>

    <v-dialog max-width="300" v-model="modal.inactive">
      <v-card>
        <v-card-title>
          <v-layout justify-center>
            <v-icon x-large> mdi-alert </v-icon>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <p class="pb-0">
            ¿ Estas seguro de inactivar a este usuario de la comisión ?
          </p>
          <p>
            El proceso iniciaría nuevamente al momento de actualizar un usuario
          </p>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn
                dark
                block
                rounded
                :loading="loading.btn"
                class="mb-5"
                @click="modal.inactive = false"
                color="#c1c1c1"
              >
                Cerrar
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                :loading="loading.btn"
                class="mb-5"
                @click="inactiveUsers()"
                color="#034f79"
                dark
                rounded
                block
              >
                Inactivar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { LISTMONTH } from "./../../../utils/util";
import Api from "@/utils/api";
import alert from "@/mixins/alert";
import color from "@/mixins/color";
export default {
  mixins: [alert, color],
  data() {
    return {
      date: null,
      document_number: null,
      step: 1,
      listMonth: LISTMONTH,
      listUserAssign: [],
      listCostCenter: [],
      listActions: [],
      listClients: [],
      listUsers: [],
      listCountry: [
        {
          id: 1,
          name: "Colombia - COP",
          country: "Colombia",
          currency: "COP",
        },
        {
          id: 2,
          name: "México - MXN",
          country: "México",
          currency: "MXN",
        },
        {
          id: 3,
          name: "Perú - PEN",
          country: "Perú",
          currency: "PEN",
        },
      ],
      show: {
        directory: false,
        manager: false,
      },
      commissionId: null,
      menu: false,
      infoUser: {
        campaignAsign: null,
        constCenter: null,
        cc: null,
        email: null,
        name: null,
        role: null
      },
      loading: {
        table: false,
        btn: false,
      },
      form: {
        campaign_id: null,
        cost_center_id: null,
        date: null,
        client_id: null,
        country: null,
        country_c: null,
        currency: null
      },
      form2: {
        commission_id: null,
        action_id: null,
        document_number: null,
      },
      form3: {
        commission_id: null,
        file: null,
      },
      min: new Date().toISOString().substr(0, 10),
      userId: null,
      modal: {
        inactive: false,
      },
    };
  },
  watch: {
    document_number: function (newM) {
      if (newM) {
        this.infoUser = {
          campaignAsign: newM.campaign == null ? " " : newM.campaign.name,
          constCenter: newM.cost_center == null ? " " : newM.cost_center.name,
          cc: newM.document_number == null ? " " : newM.document_number,
          email: newM.email == null ? " " : newM.email,
          name: newM.name == null ? " " : newM.name,
          role: newM.role_users == null && newM.role_users.length == 0 && newM.role_users[0].role == null ? " " :  newM.role_users[0].role.name
        };
        this.form2.document_number = newM.document_number;
        this.show.manager = true;
      } else {
        this.show.manager = false;
      }
    },
  },
  methods: {
    download(data) {
      window.open(
        "https://autocom.tars.dev/EjemploBaseComisionesVariables.xlsx",
        "_blank"
      );
    },
    preview() {
      this.step--;
      this.getUserCommission();
    },
    getColorStatus(status) {
      var color = this.color(status);
      return color;
    },
    getUserCommission() {
      this.loading.table = true;
      Api.Auth()
        .getUserCommissions(this.commissionId)
        .then((res) => {
          if (res.data.cod == 0) {
            this.listUserAssign = res.data.data;
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    getClient() {
      Api.Workforce()
        .getClients()
        .then((res) => {
          this.listClients = res.data.data;
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        });
    },
    getCostCenter() {
      Api.Auth()
        .getCostCenter()
        .then((res) => {
          if (res.data.cod == 0) {
            this.listCostCenter = res.data.data;
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => (this.loading.table = false));
    },
    getActions() {
      Api.Auth()
        .getActions()
        .then((res) => {
          if (res.data.cod == 0) {
            this.listActions = res.data.data;
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => (this.loading.table = false));
    },
    getUsers() {
      Api.Workforce()
        .getUserMRChispa()
        .then((res) => {
          if (res.data.cod == 0) {
            this.listUsers = res.data.data;
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => (this.loading.table = false));
    },
    addCommission() {
      if (this.$refs.form.validate()) {
        this.loading.btn = true;
        this.form.campaign_id = this.$route.params.campaignId;
        this.form.date = this.date + "-01";
        this.form.country = this.form.country_c.country;
        this.form.currency = this.form.country_c.currency;
        Api.Workforce()
          .createCommission(this.form)
          .then((res) => {
            if (res.data.cod == 0) {
              this.step++;
              this.commissionId = res.data.data.commission_id;
              this.alert(res.data.message, "Muy bien", "success");
            } else {
              this.alert(res.data.message, "Oops...", "error");
            }
          })
          .catch((error) => {
            this.alert(error.response.data.message, "Oops...", "error");
          })
          .finally(() => (this.loading.btn = false));
      }
    },
    addUserCommission() {
      if (this.$refs.form1.validate()) {
        if (!this.commissionId) {
          this.alert(
            "hubo un error al asignar usuarios, intentelo más tarde",
            "Oops...",
            "error"
          );
          return false;
        }
        this.loading.btn = true;
        this.form2.commission_id = this.commissionId;

        Api.Workforce()
          .addUserCommission(this.form2)
          .then((res) => {
            if (res.data.cod == 0) {
              // this.step++;
              this.getUserCommission();
              this.infoUser = {
                campaignAsign: null,
                constCenter: null,
                cc: null,
                email: null,
                name: null,
                role: null,
              };
              this.$refs.form1.reset();
              this.alert(res.data.message, "Muy bien", "success");
            } else {
              this.alert(res.data.message, "Oops...", "error");
            }
          })
          .catch((error) => {
            this.alert(error.response.data.message, "Oops...", "error");
          })
          .finally(() => (this.loading.btn = false));
      }
    },
    addFileCommission() {
      if (this.form3.file == null && this.form3.file2 == null) {
        this.alert(
          "Debe por lo menos ingresar un archivo de comisiones",
          "Oops...",
          "error"
        );
        return false;
      }
      if (this.$refs.form3.validate()) {
        if (!this.commissionId) {
          this.alert(
            "hubo un error al asignar usuarios, intentelo más tarde",
            "Oops...",
            "error"
          );
          return false;
        }
        this.loading.btn = true;
        const formData = new FormData();
        // formData.append("file", this.form3.file);
        if (this.form3.file != null) {
          formData.append("commission_file", this.form3.file);
        }
        if (this.form3.file2) {
          formData.append("variable_file", this.form3.file2);
        }
        formData.append("commission_id", this.commissionId);

        Api.Workforce()
          .addFileCommission(formData)
          .then((res) => {
            if (res.data.cod == 0) {
              this.commissionId = res.data.data.id;
              this.alert(res.data.message, "Muy bien", "success");
              this.$router.push({ name: "commissions" });
            } else {
              this.alert(res.data.message, "Oops...", "error");
            }
          })
          .catch((error) => {
            this.alert(error.response.data.message, "Oops...", "error");
          })
          .finally(() => (this.loading.btn = false));
      }
    },
    next() {
      if (this.listUserAssign.length == 0) {
        this.alert(
          "No ha asignado usuarios para la comisión",
          "Muy bien",
          "error"
        );
        return false;
      } else if (this.listUserAssign.length == 1) {
        this.alert(
          "La comisión debe tener un gerente y un directo",
          "Muy bien",
          "error"
        );
        return false;
      }
      this.step++;
    },
    inactiveUsers() {
      this.loading.btn = true;
      Api.Workforce()
        .inactiveUser(this.commissionId, this.userId)
        .then((res) => {
          if (res.data.cod == 0) {
            this.getUserCommission();
            this.alert(res.data.message, "Muy bien", "success");
            this.modal.inactive = false;
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.btn = false;
        });
    },
    openModalInactive(data) {
      this.userId = data.user.id;
      this.modal.inactive = true;
    },
  },
  mounted() {
    this.getClient();
    this.getCostCenter();
    this.getActions();
    this.getUsers();
  },
};
</script>
<style>
.info-text {
  color: rgba(0, 0, 0, 0.6);
  text-transform: capitalize;
}
.info-text-1 {
  color: rgba(0, 0, 0, 0.6);
}
.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
.v-stepper__header {
  box-shadow: none !important;
}
</style>
